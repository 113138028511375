
/* AUTOGENERATED VIA generate-config.js */
module.exports = {
  CONFIG_PROJECT_NAME: 'designgenie',
  CONFIG_PROJECT_DOMAIN_DEV: 'https://designgenie.local',
  CONFIG_PROJECT_DOMAIN_PROD: 'https://design-genie.net',
  DEFAULT_ITEM_IMAGE: 'default_item_image.png', // Source: projectname.itemimgs.thumb
  DEFAULT_PDF_IMAGE: 'default_pdf_image.png', // Source: projectname.pdf.image
  DEFAULT_MEDIA_IMAGE: 'default_media_image.jpg', // Source: projectname.mediaimgs.thumb
  INITIAL_PREVIEW: 'default_edition_preview.jpg', // Source: projectname.templates
  DEFAULT_CMS_IMAGE: 'default_cms_image.png', // Source: projectname.cmsimgs.thumb - currently not in use, but file exists on S3
};
